.bg-skew{
    position: fixed;
    background-color: var(--common-primary-color) ;
    width: 100%;
    height: 200%;
    /*border-style: dotted;*/
    /*transform: skewX(10deg);*/
    transform: rotate(-15deg);
    left: -78%;
    /* top: 50%; */
  }

  .home-content{
    display: flex;
    position: relative;
    align-items: center;
    z-index: 2;
    height: 98vh;
    gap: 12.5rem;
  }
  .profile{
    position: relative;
    /*border-style: dotted;*/
    height: 90vh;
    top: 2%;
    left: 5%;
    width: 28%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 1.875rem;
    box-shadow: 0.3125rem 0 0.4375rem black;
  }
.home{
    position: relative;
}
.home-details{
    display: flex;
    flex-direction: column;
    border-color: blue;
    line-height: 2.1875rem;
    max-width: 50%;
}
.intro{
    display: flex;
    flex-direction: row;
    
}

.name::before{
    display: inline-block;
    content: "";
    height: 0.25rem;
    width: 2.0625rem;
    background-color: var(--common-primary-color);
    vertical-align: middle;
    margin-right: 0.3125rem;
    transform: translateY(-50%);
  }
.name span{
  display: block;
  color: #666;
  margin-top: 1.25rem;
  margin-left: 2.375rem;
}
.name{
    display: block;
    align-items: center;
    margin-top: 0.4375rem;
    color: var(--common-primary-color);
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 3.1875rem;
    /* line-height: 0.5rem; */
}

.description{
  display: flex;
  color: #666;
  font-size: 1.25rem;
  font-family: "Poppins", sans-serif;
}

@media (min-width: 991px) and (max-width: 1300px){
  .bg-skew{
    left: -70%;
  }
  .profile{
    width: 31%;
  }
}
@media (max-width: 990px){
  .bg-skew{
      display: none;
  }
  .name::before{
    display: none;
  }

  .home{
      width: 100vw;
      height: 100vh;
  }

  .home-content{
      flex-direction: column;
      width: 100vw;
      height: 100vh;
      justify-content: start;
      margin-top: 10%;
      gap: 3.125rem;
  }

  .profile{
      position: relative;
      min-height: 16.875rem;
      width: 16.875rem;
      left: 0px;
      height: 16.875rem;
      border-radius: 50%;
      margin: 0 auto;
      box-shadow: 3px 3px 0.625rem 0rem rgba(0, 0, 0, 0.2),  /* bottom-right */
    -3px -3px 0.625rem 0rem rgba(0, 0, 0, 0.2), /* top-left */
    -3px 3px 0.625rem 0rem rgba(0, 0, 0, 0.2),  /* bottom-left */
    3px -3px 0.625rem 0rem rgba(0, 0, 0, 0.2); 
  }

  .home-details{
      width: auto;
      max-width: 100vw;
      align-items: center;
      text-align: center; /* Ensure content is centered */
  }
  .description{
    max-width: 80vw;
  }
  .name span{
    margin: 0;
    margin-top: 0.9375rem;
  }
  .name{
    line-height: 35px;
  }
}
