body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
  --common-primary-color: #ffb400;
}

/* Default base font-size */
html {
  font-size: 16px; /* Fallback value */
}

@media (max-width: 1000px) {
  html {
    font-size: 12px; /* Smaller screen, smaller font */
  }
}

@media (min-width: 768px) and (max-width: 909px) {
  html {
    font-size: 9px; /* Medium-sized screen */
  }
}

@media (min-width: 910px) and (max-width: 1024px) {
  html {
    font-size: 10px; /* Medium-sized screen */
  }
}

@media (min-width: 1025px) and (max-width: 1250px) {
  html {
    font-size: 12px; /* Medium-sized screen */
  }
}
@media (min-width: 1251px) and (max-width: 1400px) {
  html {
    font-size: 14px; /* Medium-sized screen */
  }
}

@media (min-width: 1401px) and (max-width: 1700px) {
  html {
    font-size: 16px; /* Default size for medium-large screens */
  }
}

@media (min-width: 1701px) and (max-width: 1924px) {
  html {
    font-size: 19px; /* Larger screen, slightly bigger font */
  }
}

@media (min-width: 1925px) and (max-width: 2150px) {
  html {
    font-size: 21px; /* Large desktop */
  }
}

@media (min-width: 2151px) {
  html {
    font-size: 23px; /* Very large screen */
  }
}
