.hover {
  overflow: hidden;
}
/*Animate overlay and move it 'above'*/
.hover .overlay {
  transform: translate3d(-100%, 0, 0);
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}


/* Mouse enter event */
.hover.mouseenter.top .overlay {
  animation-name: slide--enter-top;
}
.hover.mouseenter.right .overlay {
  animation-name: slide--enter-right;
}
.hover.mouseenter.bottom .overlay {
  animation-name: slide--enter-bottom;
}
.hover.mouseenter.left .overlay {
  animation-name: slide--enter-left;
}

/* Mouse leave event */
.hover.mouseleave.top .overlay {
  animation-name: slide--leave-top;
}
.hover.mouseleave.right .overlay {
  animation-name: slide--leave-right;
}
.hover.mouseleave.bottom .overlay {
  animation-name: slide--leave-bottom;
}
.hover.mouseleave.left .overlay {
  animation-name: slide--leave-left;
}

/* Sliding animations ! */
@keyframes slide--enter-top {
  0% { transform: translate3d(0, -100%, 0); }
  100% { transform: none; }
}
@keyframes slide--enter-right {
  0% { transform: translate3d(100%, 0, 0); }
  100% { transform: none; }
}
@keyframes slide--enter-bottom {
  0% { transform: translate3d(0, 100%, 0); }
  100% { transform: none; }
}
@keyframes slide--enter-left {
  0% { transform: translate3d(-100%, 0, 0); }
  100% { transform: none; }
}
@keyframes slide--leave-top {
  0% { transform: none; }
  100% { transform: translate3d(0, -100%, 0); }
}
@keyframes slide--leave-right {
  0% { transform: none; }
  100% { transform: translate3d(100%, 0, 0); }
}
@keyframes slide--leave-bottom {
  0% { transform: none; }
  100% { transform: translate3d(0, 100%, 0); }
}
@keyframes slide--leave-left {
  0% { transform: none; }
  100% { transform: translate3d(-100%, 0, 0); }
}

.layout {
  width: 75%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1.1875rem;
  padding-bottom: 2.5rem;
}

.hover {
  background-color: #4472C4;
  position: relative;
  display: flex;
  flex: 1 1 30%;
  /* margin: 1%; */
  height: 12.875rem;
  width: 20.75rem;

  align-items: center;
  justify-items: center;
}

.content {
  flex: 1;
  text-align: center;
}

.overlay {
  position: absolute;
  height: 100%;
  cursor: pointer;
  width: 100%;
  background-color: rgba(255, 180, 0, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.item1{
  background-image: url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRo54H6ouEbEfRIzdxCVVGTDsFn3sLytjsrKPTw9o8roMU1SNqBDDv5UKwqHaKr2eDoC0Q&usqp=CAU");
  background-repeat: no-repeat;
  background-color: white;
  background-position: center;
  box-shadow: 0.3125rem 0 0.4375rem black;
}

.item2{
  background-image: url("../../../public/assets/portfolio/codeforces_logo.png");
  background-repeat: no-repeat;
  background-color: white;
  background-position: center;
  box-shadow: 0.3125rem 0 0.4375rem black;
}

.item2{
  background-image: url("../../../public/assets/portfolio/codechef_logo.png");
  background-repeat: no-repeat;
  background-color: white;
  background-position: center;
  box-shadow: 0.3125rem 0 0.4375rem black;
}
.item3{
  background-image: url("../../../public/assets/portfolio/leetcode_logo.png");
  background-repeat: no-repeat;
  background-color: white;
  background-position: center;
  box-shadow: 0.3125rem 0 0.4375rem black;
  background-size: auto;
}
.item4{
  background-image: url("../../../public/assets/portfolio/atcoder_logo.png");
  background-repeat: no-repeat;
  background-color: white;
  background-position: center;
  box-shadow: 0.3125rem 0 0.4375rem black;
  /* background-size: auto; */
}
.item5{
  background-image: url("../../../public/assets/portfolio/github_logo.png");
  background-repeat: no-repeat;
  background-color: white;
  background-position: center;
  box-shadow: 0.3125rem 0 0.4375rem black;
}

.item6{
  background-image: url("../../../public/assets/portfolio/chatbot_logo.png");
  background-repeat: no-repeat;
  background-color: white;
  background-position: center;
  box-shadow: 0.3125rem 0 0.4375rem black;
}

.layout > div{
  width:  21.375rem;
  height: 13.3125rem;
}

@media (min-width: 569px) and (max-width: 991px){
  .hover{
    flex-basis: 40%;
    margin-top: 0.625rem;
    box-shadow: 0.0625rem 0.0625rem 0.625rem 0rem rgba(0, 0, 0, 0.5),  /* bottom-right */
              -0.0625rem -0.0625rem 0.625rem 0rem rgba(0, 0, 0, 0.5), /* top-left */
              -0.0625rem 0.0625rem 0.625rem 0rem rgba(0, 0, 0, 0.5),  /* bottom-left */
              0.0625rem -0.0625rem 0.625rem 0rem rgba(0, 0, 0, 0.5); 
  }
  .portfolio .portfolio-list li {
    width: 50%;
  }
  .layout > div{
    height: 11.25rem;
  }

  .portfolio .portfolio-list li figure div {
    display: none !important;
  }
  .portfolio-single-it  figure div {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
  .portfolio .slideshow {
    background: #252525;
  }

  .portfolio .slideshow nav span {
    padding: 0;
  }

  .portfolio .slideshow ul > li {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 3.75rem;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .portfolio .slideshow ul{
    padding-left: 0;
  }
  .portfolio .slideshow ul > li:after {
    display: none;
  }

  .portfolio .slideshow figure {
    padding: 1.875rem 1.875rem 1.875rem;
    border-radius: 0;
    max-width: 45rem;
    margin: 0 auto;
  }

  .portfolio .slideshow nav span.nav-prev,
  .portfolio .slideshow nav span.nav-next {
    top: 2.1875rem;
    width: 2rem;
    height: 2rem;
  }

  .portfolio .slideshow nav span.nav-next {
    right: 1.875rem;
  }

  .portfolio .slideshow nav span.nav-prev {
    left: 1.875rem;
  }

  .portfolio .slideshow nav span.nav-prev img,
  .portfolio .slideshow nav span.nav-next img {
    width: 2rem;
    height: 2rem;
  }

  .portfolio .slideshow nav span:before {
    display: none;
  }

  .portfolio .slideshow nav span.nav-close {
    right: 0;
    left: 0;
    margin: 0 auto;
    width: 2rem;
    height: 2rem;
    display: block;
    line-height: 0;
  }

  .portfolio .slideshow nav span.nav-close img {
    width: 2rem;
    height: 2rem;
  }
  .portfolio .layout .overlay{
    opacity: 0;
  }

}
@media (max-width: 568px){
  .hover{
    flex-basis: auto;
    margin-top: 0.625rem;
    box-shadow: 0.0625rem 0.0625rem 0.625rem 0rem rgba(0, 0, 0, 0.5),  /* bottom-right */
              -0.0625rem -0.0625rem 0.625rem 0rem rgba(0, 0, 0, 0.5), /* top-left */
              -0.0625rem 0.0625rem 0.625rem 0rem rgba(0, 0, 0, 0.5),  /* bottom-left */
              0.0625rem -0.0625rem 0.625rem 0rem rgba(0, 0, 0, 0.5); 
  }
  .portfolio .portfolio-list li {
    width: 50%;
  }

  .portfolio .portfolio-list li figure div {
    display: none !important;
  }
  .portfolio-single-it  figure div {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
  .portfolio .slideshow {
    background: #252525;
  }

  .portfolio .slideshow nav span {
    padding: 0;
  }

  .portfolio .slideshow ul > li {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 3.75rem;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .portfolio .slideshow ul{
    padding-left: 0;
    margin-top:0rem;
  }
  .portfolio .slideshow ul > li:after {
    display: none;
  }

  .portfolio .slideshow figure {
    padding: 1.875rem 1.875rem 1.875rem;
    border-radius: 0;
    max-width: 45rem;
    margin: 0 auto;
  }

  .portfolio .slideshow nav span.nav-prev,
  .portfolio .slideshow nav span.nav-next {
    top: 0.8125rem;
    width: 2rem;
    height: 2rem;
  }

  .portfolio .slideshow nav span.nav-next {
    right: 1.875rem;
  }

  .portfolio .slideshow nav span.nav-prev {
    left: 1.875rem;
  }

  .portfolio .slideshow nav span.nav-prev img,
  .portfolio .slideshow nav span.nav-next img {
    width: 2rem;
    height: 2rem;
  }

  .portfolio .slideshow nav span:before {
    display: none;
  }

  .portfolio .slideshow nav span.nav-close {
    right: 0;
    left: 0;
    margin: 0 auto;
    width: 2rem;
    height: 2rem;
    display: block;
    line-height: 0;
    top:0.8125rem;
  }

  .portfolio .slideshow nav span.nav-close img {
    width: 2rem;
    height: 2rem;
  }
  .portfolio .layout .overlay{
    opacity: 0;
  }

}