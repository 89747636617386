.arrowSwipeButton {
    display: flex;
    width:auto;
    align-items: center;
    align-content: center;
    border: 0.0625rem solid var(--common-primary-color);
    border-radius: 1.5625rem;
    color: #666; /* Change icon color to white on hover */
    /* background-color: var(--common-primary-color); */
    font-size: 0.9375rem;
    cursor: pointer;
    position: relative;
    margin-bottom: 1.25rem;
    padding-left: 1.3125rem;
    font-weight: 600;
    font-family: 'Poppins', Times, serif;
    overflow: hidden;
  }
  .arrowSwipeButton i{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.125rem;
    height: 3.125rem;
    position: relative;
    right: -0.0625rem;
    border-radius: 50%;
    color: white;
    background-color: var(--common-primary-color);
    margin-left: 0.9375rem;
  }
  .arrowSwipeButton:hover::before, .arrowSwipeButton:hover{
    color: white;
    left: 0%;
  }
  
  .arrowSwipeButton::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 100%;
    background-color: var(--common-primary-color);
    transition: left 250ms ease;
    z-index:-1;
  }
  
 