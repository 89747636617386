
.contact_page{
    margin: 0 auto;
    display:flex;
    align-items:row ;
    gap:5%;
    width: 80%;
    align-content: center;
    font-family: "Poppins", sans-serif;
  }

  .details{
    width: 35%;
    color: #666;
  }
  
  .details .friendly_text{
    line-height: 1.6875rem;
  }

  .details h2{
    font-weight: 700;
    margin-top: 0;
  }
  .anchor{
    font-weight: 600;
    margin: 0;
  }
  .details h2{
    color: #666;
  }
  .mail_icon, .phone_icon{
    display: flex;
    font-size: 2.5rem;
    color: var(--common-primary-color);
    padding-top: 0.375rem;
    padding-right: 0.625rem;
    
  }
  .phone_icon{
    font-size: 2.8125rem;
    padding-top: 0.125rem;
    padding-right: 0.625rem;
  }
  
  .mail{
    display: flex;
    align-items: row;
  }
  .mail_me{
    display: flex;
    flex-direction: column;
  }
  
  .mail_me > *{
    margin: 0;
  }
  
  .phone{
    display: flex;
    align-items: row;
    margin-top: 1.25rem;
    
  }
  .call_me{
    display: flex;
    flex-direction: column;
  }
  
  .call_me > *{
    margin: 0;
  }
  
  .form-row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1.25rem;
      /* display: grid;
      grid-template-columns: repeat(3, 1fr); */
  }
  .form-row input{
    margin-left:0.9375rem;
  }
  .form-control {
      border: 0.0625rem solid #ddd;
      border-radius: 1.875rem;
      padding: 0.625rem 1.25rem;
      /* width: 30%; */
      font-size: 1rem;
      outline: none;
      transition: border 0.3s ease;
      font-family: "Poppins", sans-serif;
  }
  
  .form-control:focus {
      border-color: var(--common-primary-color);
  }
  
  .message {
      margin: 0.9375rem;
      margin-right: 0;
      margin-bottom: 0;
      width: calc(100% - 40px -2px); /* 100% - padding-left(20)- padding-right(20) -margin-left(15) - border*/
      height: 9.375rem;
      border-radius: 1.25rem;
      resize: both;
  }
  
.send_me_button{
    display: flex;
    font-size: 0.625rem;
    margin: 0.9375rem;
}

/* .input-row{
  /* display: flex;
  flex-direction: column;
  display: block;
  width:100%;
} */

.input-row {
  display: flex;
  flex-direction: column;
}
input, textarea{
  color: #666;
}


.input-row .error-message{
  padding:0 1.25rem;
  color: #dc3232;
}

/**
 * Configuration.
 */
/**
 * Buttons.
 */
 .button {
  margin-bottom: 0.25rem;
  padding: 0.5rem 0.75rem;
  border: 0.0625rem solid #75B9E1;
  border-radius: 0.1875rem;
  background: #4FA0D0;
  cursor: pointer;
  font-family: inherit;
  text-transform: uppercase;
  /* cursor: pointer; */
  color: #fff; }
  .button:focus {
    outline: none; }
  .button--warning {
    border: 0.0625rem solid #FFB039;
    background: #ff9800; }
  .button--success {
    border: 0.0625rem solid #71E6AB;
    background: #4AD890; }
  .button--error {
    border: 0.0625rem solid #F578A4;
    background: #EF5289; }

/**
 * Popups.
 */
.popup {
  visibility: hidden;
  transition: visibility 0ms linear 0.3s; }
  .popup--visible {
    visibility: visible;
    transition: visibility 0ms; }
  .popup__background {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.4);
    opacity: 0;
    transition: opacity 0.3s ease-in-out; }
    .popup--visible .popup__background {
      opacity: 1; }
  .popup__content {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 10001;
    min-width: 25rem;
    padding: 1.5625rem 3.125rem;
    background: #fff;
    border: 0.0625rem solid #ddd;
    border-radius: 0.1875rem;
    text-align: center;
    animation: hide-popup 0.3s forwards;
    /**
		 * Popup types.
		 */ }
    .popup--visible .popup__content {
      animation: show-popup 0.3s forwards; }
    .popup--icon .popup__content {
      padding-top: 8.125rem;
      /**
			 * Animations on opened popups.
			 *
			 * We need to prepend ".popup--visible" with no space to "&" to match the
			 * an opened popup: ".popup--visible.popup--icon".
			 *
			 * Therefore we need the "at-root" function to break out of nesting as well as the
			 * "selector-append" function to append parent selectors without space.
			 *
			 * Details:
			 *  - https://css-tricks.com/the-sass-ampersand/
			 *  - http://sass-lang.com/documentation/Sass/Script/Functions.html#selector_append-instance_method
			 */
      /**
			 * Different popup icon styles
			 *
			 * E.g. selector for type question: ".popup--icon.-question" to match class="popup--icon -question"
			 *
			 * To have an easier selector in SCSS we use a little workaround and rearrange the selectors:
			 *  ".-question.popup--icon" is also matching class="popup--icon -question"
			 */ }
      .popup--icon .popup__content:before, .popup--icon .popup__content:after {
        position: absolute;
        top: 1.5625rem;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        height: 5.625rem;
        width: 5.625rem; }
      .popup--icon .popup__content:before {
        content: '';
        border: 0.1875rem solid currentColor;
        border-radius: 50%;
        transform: translateX(-50%) scale(1, 0);
        opacity: 0; }
      .popup--icon .popup__content:after {
        content: '\2713';
        line-height: 5.625rem;
        font-size: 2.8125rem;
        transform: translateX(-50%) scale(0);
        opacity: 0; }
      .popup--visible.popup--icon .popup__content:before {
        animation: show-icon-cirlce 0.3s forwards 0.15s; }
      .popup--visible.popup--icon .popup__content:after {
        animation: show-icon 0.3s forwards 0.3s; }
      .-question.popup--icon .popup__content:before {
        border-color: #ff9800; }
      .-question.popup--icon .popup__content:after {
        content: '?';
        color: #ff9800; }
      .-success.popup--icon .popup__content:before {
        border-color: #4AD890; }
      .-success.popup--icon .popup__content:after {
        content: '\2713';
        color: #4AD890; }
      .-error.popup--icon .popup__content:before {
        border-color: #EF5289; }
      .-error.popup--icon .popup__content:after {
        content: '\2717';
        color: #EF5289; }
    .popup__content__title {
      margin-bottom: 0.625rem;
      font-size: 1.75rem;
      font-weight: 100;
      color: #626262; }

/**
 * Popup animations.
 * Based on Sweet Alert: "https://t4t5.github.io/sweetalert/"
 */
@keyframes show-popup {
  0% {
    transform: translate(-50%, -50%) scale(0.7);
    opacity: 0; }
  45% {
    transform: translate(-50%, -50%) scale(1.05);
    opacity: 1; }
  80% {
    transform: translate(-50%, -50%) scale(0.95); }
  100% {
    transform: translate(-50%, -50%) scale(1); } }

@keyframes hide-popup {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1; }
  100% {
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 0; } }

/**
 * Icon animations.
 */
@keyframes show-icon {
  0% {
    transform: translateX(-50%) scale(0);
    opacity: 0; }
  100% {
    transform: translateX(-50%) scale(1);
    opacity: 1; } }

@keyframes show-icon-cirlce {
  0% {
    transform: translateX(-50%) scale(1, 0);
    opacity: 0; }
  100% {
    transform: translateX(-50%) scale(1, 1);
    opacity: 1; } }

@media (max-width: 700px){
  .contact_page{
    flex-direction: column;
    margin-top: 1.875rem;
  }
  .contact_page .details{
    width: 80vw;
  }
  .contact_page .contact-form{
    margin-top: 1.875rem;
    width: 80vw;
  }
  .form-row .input-row{
    margin-top: 0.9375rem;
  }
  .form-row{
    flex-direction: column;
    margin-bottom: 0rem;
  }
}