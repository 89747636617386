.icon-container {
  display: flex; 
  flex-direction: column;
  align-items: flex-end;
  gap: 0.625rem; /* Adjust the gap to add distance between icons */
  position: fixed;
  right: 0.625rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;
}
.mobile-navigator{
  display: none;
}

/* Mobile  Navigation */

@media (max-width: 61.9375rem) {
  .mobile-navigator{
    display: inline-block;
  }
  .icon-container{
    display: none;
  
  }
  #menuToggle {
    display: inline-block;
    font-family: "Poppins";
    position: fixed;
    top: 0.9375rem;
    width: 3.375rem;
    height: 3.375rem;
    right: 1.875rem;
    z-index: 1111;
    user-select: none;
    padding: 1.1875rem 0 0 1rem;
    width: 2.375rem;
    height: 2.1875rem;
    border-radius: 0.3125rem;
    background: rgba(30, 37, 48, 0.07);
  }

  #menuToggle input {
    display: flex;
    width: 3.375rem;
    height: 3.375rem;
    position: absolute;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
    top: 0;
    left: 0;
  }

  #menuToggle > span {
    display: flex;
    width: 1.4375rem;
    height: 0.125rem;
    margin-bottom: 0.3125rem;
    position: relative;
    background: #666;
    border-radius: 0.1875rem;
    z-index: 1;
    transform-origin: 0.3125rem 0;
  }

  #menuToggle > span:first-child {
    transform-origin: 0 0;
  }

  #menuToggle > span:nth-last-child(2) {
    transform-origin: 0 100%;
  }

  #menuToggle input:checked ~ span {
    opacity: 1;
    transform: rotate(45deg) translate(0.25rem, 0);
    background: #666;
  }

  #menuToggle input:checked ~ span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }

  #menuToggle input:checked ~ span:nth-last-child(2) {
    transform: rotate(-45deg) translate(0.125rem, 0.25rem);
  }
  .menu-bar{
    width:0rem;
    height: 0rem;
  }
  #menu {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding-top: 3.75rem;
    background-color: #dedee3;
    -webkit-font-smoothing: antialiased;
    transform-origin: 0 0;
    transform: translate(-100%, 0);
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  }


  #menuToggle input:checked ~ div ul {
    transform: none;
  }

  #menu li {
    position: relative;
    padding-left: 1.875rem;
    list-style: none;
  }

  #menu li:after {
    position: absolute;
    content: '';
    height: 0.0625rem;
    width: calc(100% - 3.75rem);
    background: #fff;
    left: 1.875rem;
  }

  #menu li:last-child:after {
    display: none;
  }

  #menu li.active span {
    font-weight: 600;
    color: orange;
  }
 #menu li.active i {
    color: orange;
  }
  #menu li{
    color: #666;
    text-transform: uppercase;
    transition-delay: 2s;
    font-size: 1.625rem;
    display: block;
    text-decoration: none;
    padding: 0.875rem 0;
    position: relative;
  }

  #menu li span {
    position: absolute;
    left: 3.125rem;
    font-weight: 400;
  }
}