.about-page{
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
    align-items: center;
}

/* .infos > div {
    border: 0.125rem solid #000;
} */
.infos{
  display: flex;
  justify-content: center;
  width: 80%;
  font-family: "Poppins", sans-serif;
  color: #666;
  /* align-items: center; */
}

.personal_infos h2{
  font-size: 1.625rem;
  font-weight: 700;
}
.personal_infos span{
  color: #666;
  font-weight: 600;
  opacity: 1;
}
.personal_infos, .ratings{
    padding-top: 0rem;
    flex: 1 1 48%; /* Each inner container takes up 48% of the flex container's width */
    box-sizing: border-box; /* Include padding and border in element's total width and height */
    /* background-color: #f0f0f0; */
    /* border: 0.125rem solid #000;  */
}
.personal_infos ul{
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns layout */
    gap: 0.3125rem; /* Space between grid items */
    list-style-type: none; /* Remove default list styles */
    padding: 0; /* Remove default padding */
    margin: 0; /* Remove default margin */
    font-weight: 600;
  }
.ratings ul{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.625rem;
  list-style-type: none;
}
.ratings h3{
  font-size: 2.5rem;
  padding: 0%;
  margin: 0%;
  display: inline-block;
  position: relative;

  color: var(--common-primary-color);
}
.ratings span {
  content: '+';
  right: -1.5rem;
  color: var(--common-primary-color);
  font-size: 2.0625rem;
  font-weight: 300;
  top: 0.125rem;
}
.ratings li{
  border: 0.0625rem solid #ddd;
  padding-left: 1.25rem;
  
}
.ratings p::before{
  content: "";
  display: inline-block;
  width: 1.25rem;   /* Adjust the width to your desired dash length */
  height: 0.125rem;   /* Adjust the height to your desired dash thickness */
  background-color: #666; /* Set the color of the dash */
  margin-right: 0.3125rem;
  vertical-align: middle; /* Align the dash with the text */
}
.ratings p{
  padding-left: 1.5625rem;
  text-indent: -1.5625rem;
}
.personal_infos li {
    padding: 0.625rem;
    padding-top: 0rem;
    color: rgba(102, 102, 102, 0.8); 
}

.personal_infos .download_button{
  display: flex;
  padding-top: 0.625rem;
}

.personal_infos a{
  text-decoration: none;
}

.experience {
  width: 80%;
  height: auto;
  color: #666;
  margin-top: 1.25rem;
  margin-bottom: 3.75rem;
}

.experience h2 {
  color: #666;
  text-align: center;
  margin-bottom: 1.25rem;
}

.experience-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.25rem;
  
}

.experience-item {
  justify-self: center; /* Center horizontally */
  align-self: center; /* Center vertically */
  width: auto;
  height: auto;
  display: flex;
  padding: 0.625rem;
  position: relative;
  min-height: 12.5rem;
  /* box-sizing: border-box; */
  border-radius: 0.5rem;
  box-shadow: 0rem 0rem 0.25rem rgba(47, 47, 47, 0.2);
}
.experience-item::before{
  content: "";
  top: 0.625rem;
  position: absolute;
  bottom: 0;
  left:2.1875rem;  
  border-left: 0.0625rem solid #d0cdcd;
}

.experience-info{
  width: 90%;
}
.experience-item .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 50%;
  background-color: var(--common-primary-color);
  margin-right: 0.9375rem;
  z-index: 1;
}

.icon i {
  color: white;
  font-size: 1.25rem;
}

.experience-info .time {
  display: inline-block;
  background-color: #e5e3e3;
  border-radius: 1.5625rem;
  padding: 0.3125rem 0.625rem;
  margin-bottom: 0.3125rem;
  font-size: 0.75rem;
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  color: #666;
}

ul .responsibilities, .projects, .projects h3{
  font-family: "Poppins", sans-serif;
  padding: 0;
  margin-bottom: 0rem;
}
.responsibilities li{
  margin-left: 1.875rem;
}
.role {
  font-weight: 700;
  font-size: 1.125rem;
  font-family: "Poppins", sans-serif;
  color: #666;
  margin: 0;
}

.role span {
  /* font-weight: normal; */
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
  color: #666;
  opacity: 0.8;
}


@media (max-width: 768px) {
  .infos {
    flex-direction: column; /* Stack the containers vertically on small screens */
  }

  .personal_infos, .ratings {
    flex: 1 1 100%; /* Each container takes up 100% of the width on small screens */
  }

  .experience-list{
    grid-template-columns: 1fr;
  }
  .experience-list .experience-item:nth-child(odd) {
    order: 1;
  }

  .experience-list .experience-item:nth-child(even) {
    order: 2;
  }
  .experience-list .experience-item{
    min-width: 80vw;
  }
  .infos{
    width: 100vw;
    margin-left: 0.625rem;
  }
  .infos{
    align-items: center;
  }
  .ratings ul{
    padding-left: 0;
    width: 80vw;
  }
  .about_profile{
    position: relative;
    min-height: 12.5rem;
    width: 12.5rem;
    height: 12.5rem;
    border-radius: 50%;
    margin: 1.25rem auto;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

}
}
