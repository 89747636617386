.preloader {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #fff;
    transform-origin: bottom;
    z-index: 111111;
    transition: opacity 1.2s ease;
  }
  
  .preloader .black_wall {
    height: 100%;
    background-color: rgb(238, 238, 238);
    transform-origin: top;
    animation: preloader_slide 0.5s ease-in-out 0s 1 normal both;
  }
  
  .preloader .loader {
    width: 100%;
    height: 0.1875rem;
    position: absolute;
    border-radius: 0.125rem;
    top: 0;
    right: 0;
    left: 0;
    margin: auto;
    background-color: #FFC41F;
    transform-origin: left;
    animation: loader_running 3s ease-in-out 1s infinite normal both;
  }
  
  @keyframes loader_running {
    0% {
      transform: scaleX(0);
      transform-origin: left;
    }
    49% {
      transform: scaleX(1);
      transform-origin: left;
    }
    50% {
      transform: scaleX(1);
      transform-origin: right;
    }
    100% {
      transform: scaleX(0);
      transform-origin: right;
    }
  }
  
  @keyframes preloader_slide {
    from {
      transform: scaleY(0);
    }
    to {
      transform: scaleY(1);
    }
  }
  