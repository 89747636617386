  .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.125rem;
    height: 3.125rem;
    text-align: center;
    border-radius: 50%;
    color: #666;
    background-color: #EEEEEE;
    font-size: 1.5rem;
    transition: 0.2s;
    cursor: pointer;
    position: relative;
    margin-bottom: 1.25rem; /* Adds distance between icons */
  }
  
  /* .icon-wrapper{
    border-color: red;
    border-style: dotted;
  } */
  .icon-wrapper:hover {
    border-radius: 1.5625rem;
  }
  
  .icon-wrapper::before {
    content: attr(data-label);
    position: absolute;
    display: none;
    right: 3.1875rem; /* Adjusted to appear to the left of the circle */
    color: white;
    font-size: 1rem;
  }
  
 
  
  .icon-wrapper:hover {
    color: white; /* Change icon color to white on hover */
    background-color: var(--common-primary-color);
    z-index: 101;
  }
  .icon-wrapper:hover::before {
    display: inline-block;
  }
  